import React, { useState } from "react";
import { Link } from "gatsby";

import styles from "./MobileMenu.module.css";

const MobileMenu = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={styles.MobileMenu}>
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setOpen(!isOpen)}
      >
        <rect width="20" height="2" fill="#101113" />
        <rect width="20" height="2" fill="#101113" />
        <rect width="20" height="2" fill="#101113" />
        <rect y="6" width="20" height="2" fill="#101113" />
        <rect y="6" width="20" height="2" fill="#101113" />
        <rect y="6" width="20" height="2" fill="#101113" />
        <rect y="12" width="20" height="2" fill="#101113" />
        <rect y="12" width="20" height="2" fill="#101113" />
        <rect y="12" width="20" height="2" fill="#101113" />
      </svg>

      {isOpen && (
        <div className={styles.MobileMenuBody}>
          <a href="https://coach.thefitlab.io/account/signin">
            <span className={styles.Link}>Sign In</span>
          </a>
          <a href="https://coach.thefitlab.io/account/signup">
            <span className={styles.LinkSignUp}>GETTING STARTED</span>
          </a>
          <Link to="/#key">
            <span className={styles.Link}>Key Features</span>
          </Link>
          <Link to="/#how">
            <span className={styles.Link}>How it works</span>
          </Link>
          <a href="https://blog.thefitlab.io">
            <span className={styles.Link}>Blog</span>
          </a>
          <Link to="/#contacts">
            <span className={styles.Link}>Contanct us</span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
