import React from "react";

import Logo from "./Logo";
import TopMenu from "./TopMenu";
import MobileMenu from "./MobileMenu";
import SignIn from "./SignIn";

import styles from "./Header.module.css";

const Header = () => (
  <header className={styles.Header}>
    <div className={styles.HeaderInner}>
      <div className={styles.HeaderLogo}>
        <Logo />
      </div>
      <div className={styles.HeaderMenu}>
        <TopMenu />
      </div>
      <div className={styles.HeaderSignIn}>
        <SignIn />
      </div>
      <div className={styles.HeaderMobileMenu}>
        <MobileMenu />
      </div>
    </div>
  </header>
);

export default Header;
