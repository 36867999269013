/* eslint-disable react/jsx-no-target-blank, jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "gatsby";

import styles from "./Footer.module.css";

const Footer = () => (
  <footer className={styles.Footer} id="contacts">
    <div className={styles.FooterTop}>
      <div className={styles.FooterAbout}>
        <div className={styles.FooterTitle}>Fitlab Platform</div>
        <div className={styles.FooterText}>
          Fitlab is a personal trainer app to track clients and to build
          training programs.
        </div>
        <div className={styles.FooterLinks}>
          <div>
            <Link to="/privacy/">Privacy</Link>
          </div>
          <div>
            <Link to="/terms/">Terms of use</Link>
          </div>
        </div>
      </div>
      <div className={styles.FooterContact}>
        <div className={styles.FooterTitle}>Contact Us</div>
        <ul className={styles.FooterContactsList}>
          <li className={styles.FooterContactsListItem}>
            <a
              href="tel:+12159877815"
              className={styles.FooterContactsListLink}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.557 3.59453L16.0609 1.10078C15.7562 0.796094 15.3484 0.625 14.9172 0.625C14.4859 0.625 14.0781 0.79375 13.7734 1.10078L11.0852 3.78437C10.7805 4.08906 10.6094 4.49922 10.6094 4.93047C10.6094 5.36406 10.7781 5.76953 11.0852 6.07656L13.1852 8.17891C12.7068 9.29356 12.0194 10.3063 11.1602 11.1625C10.3 12.0273 9.29453 12.7094 8.17891 13.1922L6.07891 11.0898C5.77422 10.7852 5.36641 10.6141 4.93516 10.6141C4.72244 10.6133 4.51172 10.655 4.31532 10.7366C4.11892 10.8183 3.9408 10.9384 3.79141 11.0898L1.10078 13.7734C0.796094 14.0781 0.625 14.4883 0.625 14.9195C0.625 15.3531 0.79375 15.7586 1.10078 16.0656L3.59453 18.5594C4.11484 19.0797 4.83203 19.3773 5.56797 19.3773C5.72031 19.3773 5.86797 19.3656 6.01797 19.3398C9.12109 18.8289 12.2008 17.1766 14.6875 14.6922C17.1719 12.2031 18.8219 9.12344 19.3375 6.01797C19.4852 5.13672 19.1898 4.22969 18.557 3.59453ZM17.6758 5.73906C17.2188 8.50234 15.7328 11.2586 13.4945 13.4969C11.2562 15.7352 8.50234 17.2211 5.73906 17.6781C5.39219 17.7367 5.03594 17.6195 4.78281 17.3687L2.33359 14.9195L4.93047 12.3203L7.73828 15.1328L7.75937 15.1539L8.26562 14.9664C9.80061 14.402 11.1945 13.5107 12.3508 12.354C13.507 11.1974 14.3979 9.80315 14.9617 8.26797L15.1492 7.76172L12.318 4.93281L14.9148 2.33359L17.3641 4.78281C17.6172 5.03594 17.7344 5.39219 17.6758 5.73906Z"
                  fill="white"
                />
              </svg>
              <span className={styles.FooterText}>+1(832) 558-1392‬</span>
            </a>
          </li>
          <li className={styles.FooterContactsListItem}>
            <a
              href="malito:support@thefitlab.io"
              className={styles.FooterContactsListLink}
            >
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.75 0.75H1.25C0.835156 0.75 0.5 1.08516 0.5 1.5V16.5C0.5 16.9148 0.835156 17.25 1.25 17.25H20.75C21.1648 17.25 21.5 16.9148 21.5 16.5V1.5C21.5 1.08516 21.1648 0.75 20.75 0.75ZM19.8125 3.34687V15.5625H2.1875V3.34687L1.54063 2.84297L2.46172 1.65938L3.46484 2.43984H18.5375L19.5406 1.65938L20.4617 2.84297L19.8125 3.34687ZM18.5375 2.4375L11 8.29688L3.4625 2.4375L2.45938 1.65703L1.53828 2.84062L2.18516 3.34453L10.1914 9.56953C10.4217 9.74841 10.7049 9.84551 10.9965 9.84551C11.2881 9.84551 11.5713 9.74841 11.8016 9.56953L19.8125 3.34687L20.4594 2.84297L19.5383 1.65938L18.5375 2.4375Z"
                  fill="white"
                />
              </svg>

              <span className={styles.FooterText}>support@thefitlab.io</span>
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.FooterSocial}>
        <div className={styles.FooterTitle}>Find Us in Social Media</div>
        <ul className={styles.FooterSocialList}>
          <li className={styles.FooterSocialListItem}>
            <a
              className={styles.FooterSocialIcon}
              href="https://www.facebook.com/thefitlab.io/"
              target="_blank"
            >
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7501 1.96017C19.0329 2.26954 18.2524 2.4922 17.4485 2.57892C18.2832 2.08306 18.9082 1.2997 19.2063 0.375791C18.4231 0.841703 17.5651 1.16841 16.6704 1.34142C16.2964 0.941634 15.8442 0.62315 15.3417 0.405808C14.8393 0.188467 14.2975 0.0769255 13.7501 0.0781347C11.5352 0.0781347 9.75399 1.87345 9.75399 4.07657C9.75399 4.38595 9.79149 4.69532 9.85243 4.99298C6.53602 4.81954 3.57821 3.23517 1.6118 0.809385C1.2535 1.42137 1.06574 2.1182 1.06805 2.82735C1.06805 4.21485 1.77352 5.43829 2.8493 6.15782C2.21533 6.13285 1.5962 5.9586 1.04227 5.64923V5.69845C1.04227 7.64142 2.41571 9.25157 4.24618 9.62189C3.90249 9.71116 3.54893 9.75683 3.19384 9.75782C2.93368 9.75782 2.68759 9.73204 2.43915 9.69689C2.9454 11.2813 4.41962 12.432 6.17509 12.4695C4.80165 13.5453 3.08134 14.1781 1.21337 14.1781C0.87821 14.1781 0.568835 14.1664 0.247742 14.1289C2.01962 15.2656 4.12196 15.9219 6.38602 15.9219C13.736 15.9219 17.7579 9.83282 17.7579 4.54767C17.7579 4.37423 17.7579 4.20079 17.7462 4.02735C18.5243 3.45782 19.2063 2.75235 19.7501 1.96017Z"
                  fill="white"
                />
              </svg>
            </a>
          </li>
          <li className={styles.FooterSocialListItem}>
            <a
              href="https://twitter.com/FitLab4"
              target="_blank"
              className={styles.FooterSocialIcon}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM17.875 17.875H13.5602V12.1141H15.9977L16.3633 9.28516H13.5602V7.47812C13.5602 6.65781 13.7875 6.1 14.9617 6.1H16.4594V3.56875C16.1992 3.53359 15.3109 3.45625 14.275 3.45625C12.1141 3.45625 10.6352 4.77578 10.6352 7.19687V9.28281H8.19297V12.1117H10.6375V17.875H2.125V2.125H17.875V17.875Z"
                  fill="white"
                />
              </svg>
            </a>
          </li>
          <li className={styles.FooterSocialListItem}>
            <a
              href="https://www.linkedin.com/company/thefitlab/"
              target="_blank"
              className={styles.FooterSocialIcon}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.868 0.625H2.13203C1.3 0.625 0.625 1.3 0.625 2.13203V17.868C0.625 18.7 1.3 19.375 2.13203 19.375H17.868C18.7 19.375 19.375 18.7 19.375 17.868V2.13203C19.375 1.3 18.7 0.625 17.868 0.625ZM17.868 17.875C7.37266 17.8727 2.125 17.8703 2.125 17.868C2.12734 7.37266 2.12969 2.125 2.13203 2.125C12.6273 2.12734 17.875 2.12969 17.875 2.13203C17.8727 12.6273 17.8703 17.875 17.868 17.875ZM3.40469 7.65391H6.18672V16.6023H3.40469V7.65391ZM4.79688 6.43047C5.68516 6.43047 6.40937 5.70859 6.40937 4.81797C6.40937 4.60621 6.36767 4.39653 6.28663 4.20089C6.2056 4.00525 6.08682 3.82749 5.93709 3.67776C5.78735 3.52802 5.60959 3.40925 5.41395 3.32821C5.21831 3.24718 5.00863 3.20547 4.79688 3.20547C4.58512 3.20547 4.37544 3.24718 4.1798 3.32821C3.98416 3.40925 3.8064 3.52802 3.65667 3.67776C3.50693 3.82749 3.38815 4.00525 3.30712 4.20089C3.22608 4.39653 3.18437 4.60621 3.18437 4.81797C3.18203 5.70859 3.90391 6.43047 4.79688 6.43047ZM10.7102 12.175C10.7102 11.0078 10.9328 9.87812 12.3789 9.87812C13.8039 9.87812 13.825 11.2117 13.825 12.25V16.6023H16.6047V11.6945C16.6047 9.28516 16.0844 7.43125 13.2695 7.43125C11.9172 7.43125 11.0102 8.17422 10.6375 8.87734H10.6V7.65391H7.93047V16.6023H10.7102V12.175Z"
                  fill="white"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className={styles.FooterBottom}>
      <div className={styles.FooterCopyright}>
        Copyright &copy; 2021 FitLab, Inc. All Rights Reserved.
      </div>
    </div>
  </footer>
);

export default Footer;
