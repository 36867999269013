import React, { useState } from "react";
import { Link } from "gatsby";

import styles from "./PrivacyBanner.module.css";

const PRIVACY_COOKIE = "fitlab.privacy";

function getCookie(cname) {
  if (!global || !global.document) {
    return "";
  }
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(global.document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const PrivacyBanner = () => {
  const [, setAccepted] = useState(false);
  const cookie = getCookie(PRIVACY_COOKIE);
  if (cookie) {
    return null;
  }

  const accpetPrivacy = (e) => {
    e.preventDefault();
    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    global.document.cookie = `${PRIVACY_COOKIE}=1; ${expires} ;path=/`;
    setAccepted(true);
  };

  return (
    <div className={styles.PrivacyBanner}>
      <div>
        <p className={styles.PrivacyBannerContent}>
          We use cookies to provide and improve our services. By using our site,
          you consent to cookies.
          <Link
            to="/"
            className={styles.PrivacyBannerAccept}
            onClick={accpetPrivacy}
          >
            Accept
          </Link>
          <Link className={styles.PrivacyBannerLearn} to="/privacy/">
            Learn more
          </Link>
        </p>
      </div>
    </div>
  );
};

export default PrivacyBanner;
