import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import "../styles/layout.css";

import Header from "./Header";
import Footer from "./Footer";
import LayoutBase from "./LayoutBase";
import PrivacyBanner from "./PrivacyBanner";

import styles from "./Layout.module.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <LayoutBase>
      <div className={styles.LayoutMain}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className={styles.LayoutBody}>{children}</div>
        <Footer />
        <PrivacyBanner />
      </div>
    </LayoutBase>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
