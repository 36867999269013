import React from "react";
import { Link } from "gatsby";

import styles from "./TopMenu.module.css";

const TopMenu = () => (
  <div className={styles.TopMenu}>
    <Link to="/#key">
      <span className={styles.Link}>Key Features</span>
    </Link>
    <Link to="/#how">
      <span className={styles.Link}>How it works</span>
    </Link>
    <a href="/blog/">
      <span className={styles.Link}>Blog</span>
    </a>
    {/* <Link to="/#contacts">
      <span className={styles.Link}>Contanct us</span>
    </Link> */}
  </div>
);

export default TopMenu;
